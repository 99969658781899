import React from 'react';
import Logins from './../../components/Login';

const Login = () => {
    return (
        <>
            <Logins />
        </>
    );
};

export default Login;