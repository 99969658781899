import React from 'react';

const Mypage = () => {
    return (
        <div>
            마이페이지임니다람쥐
        </div>
    );
};

export default Mypage;